// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.badge {
  width: 140px;
  font-size: 0.75rem;
  font-weight: bold;
  color: #757575;
  background-color: #eaeaea;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0.45rem 1.5rem;
  border-radius: 10px;
}
.badge--grey {
  color: #757575;
  background-color: #eaeaea;
}
.badge--yellow {
  color: #a59508;
  background-color: #ffe27d;
}
.badge--red {
  color: #e9522a;
  background-color: #ffdbcd;
}
.badge--blue {
  color: #357acb;
  background-color: #b5d7ff;
}
.badge--green {
  color: #12b118;
  background-color: #a0f692;
}
.badge--contrast-red {
  color: #ffffff;
  background-color: #af1a00;
}`, "",{"version":3,"sources":["webpack://./src/components/Badge/styles.scss"],"names":[],"mappings":"AAAA;EACI,YAAA;EACA,kBAAA;EACA,iBAAA;EACA,cAAA;EACA,yBAAA;EACA,oBAAA;EACA,uBAAA;EACA,mBAAA;EACA,uBAAA;EACA,mBAAA;AACJ;AACI;EACI,cAAA;EACA,yBAAA;AACR;AAEI;EACI,cAAA;EACA,yBAAA;AAAR;AAGI;EACI,cAAA;EACA,yBAAA;AADR;AAII;EACI,cAAA;EACA,yBAAA;AAFR;AAKI;EACI,cAAA;EACA,yBAAA;AAHR;AAMI;EACI,cAAA;EACA,yBAAA;AAJR","sourcesContent":[".badge {\n    width: 140px;\n    font-size: 0.75rem;\n    font-weight: bold;\n    color: #757575;\n    background-color: #eaeaea;\n    display: inline-flex;\n    justify-content: center;\n    align-items: center;\n    padding: 0.45rem 1.5rem;\n    border-radius: 10px;\n\n    &--grey {\n        color: #757575;\n        background-color: #eaeaea;\n    }\n\n    &--yellow {\n        color: #a59508;\n        background-color: #ffe27d;\n    }\n\n    &--red {\n        color: #e9522a;\n        background-color: #ffdbcd;\n    }\n\n    &--blue {\n        color: #357acb;\n        background-color: #b5d7ff;\n    }\n\n    &--green {\n        color: #12b118;\n        background-color: #a0f692;\n    }\n\n    &--contrast-red {\n        color: #ffffff;\n        background-color: #af1a00;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
